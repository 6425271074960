<template>
  <div class="test">
    <div class="d-flex">
      <FSelect
        v-model="year1"
        :options="yearsList"
        has-arrow
        no-margin
        size="x-small"
        class="orange-variant"
        style="width: 120px; margin-right: 20px;"
        popper-class="header-select-popper"
      />

      <FSelect
        v-model="year2"
        :options="yearsList"
        has-arrow
        no-margin
        size="x-small"
        class="grey-variant"
        style="width: 120px; margin-right: 20px;"
        popper-class="header-select-popper"
      />

      <FSpacer />
    </div>
  </div>

  <div class="test">
    <div class="d-flex flex-column">
      <AppText variant="div">
        0 {{ t('common.payment', 0) }}
      </AppText>
      <AppText variant="div">
        1 {{ t('common.payment', 1) }}
      </AppText>
      <AppText variant="div">
        2 {{ t('common.payment', 2) }}
      </AppText>
      <AppText variant="div">
        3 {{ t('common.payment', 3) }}
      </AppText>
      <AppText variant="div">
        4 {{ t('common.payment', 4) }}
      </AppText>
      <AppText variant="div">
        5 {{ t('common.payment', 5) }}
      </AppText>
      <AppText variant="div">
        6 {{ t('common.payment', 6) }}
      </AppText>
      <AppText variant="div">
        7 {{ t('common.payment', 7) }}
      </AppText>
      <AppText variant="div">
        8 {{ t('common.payment', 8) }}
      </AppText>
      <AppText variant="div">
        9 {{ t('common.payment', 9) }}
      </AppText>
      <AppText variant="div">
        10 {{ t('common.payment', 10) }}
      </AppText>
      <AppText variant="div">
        101 {{ t('common.payment', 101) }}
      </AppText>
      <AppText variant="div">
        417 {{ t('common.payment', 417) }}
      </AppText>
      <AppText variant="div">
        718 {{ t('common.payment', 718) }}
      </AppText>
      <AppText variant="div">
        1256 {{ t('common.payment', 1256) }}
      </AppText>
    </div>
  </div>

  <div class="test">
    <AppText>English</AppText>
    <div class="test2">
      <div>400:</div> <AppText variant="div">
        The quick brown fox jumps over the lazy dog
      </AppText>
      <div>500:</div> <AppText variant="div" class="font-medium">
        The quick brown fox jumps over the lazy dog
      </AppText>
      <div>600:</div> <AppText variant="div" class="font-semibold">
        The quick brown fox jumps over the lazy dog
      </AppText>
      <div>700:</div> <AppText variant="div" style="font-weight: bold;">
        The quick brown fox jumps over the lazy dog
      </AppText>
    </div>

    <AppText>Russian</AppText>
    <div class="test2">
      <div>400:</div> <AppText variant="div">
        Быстрая коричневая лиса перепрыгивает через ленивую собаку
      </AppText>
      <div>500:</div> <AppText variant="div" class="font-medium">
        Быстрая коричневая лиса перепрыгивает через ленивую собаку
      </AppText>
      <div>600:</div> <AppText variant="div" class="font-semibold">
        Быстрая коричневая лиса перепрыгивает через ленивую собаку
      </AppText>
      <div>700:</div> <AppText variant="div" style="font-weight: bold;">
        Быстрая коричневая лиса перепрыгивает через ленивую собаку
      </AppText>
    </div>

    <AppText>Chinese</AppText>
    <div class="test2">
      <div>400:</div> <AppText variant="div">
        敏捷的棕狐狸跳过懒惰的狗
      </AppText>
      <div>500:</div> <AppText variant="div" class="font-medium">
        敏捷的棕狐狸跳过懒惰的狗
      </AppText>
      <div>600:</div> <AppText variant="div" class="font-semibold">
        敏捷的棕狐狸跳过懒惰的狗
      </AppText>
      <div>700:</div> <AppText variant="div" style="font-weight: bold;">
        敏捷的棕狐狸跳过懒惰的狗
      </AppText>
    </div>

    <AppText>Japanese</AppText>
    <div class="test2">
      <div>400:</div> <AppText variant="div">
        素早い茶色の狐は怠け者の犬を飛び越える
      </AppText>
      <div>500:</div> <AppText variant="div" class="font-medium">
        素早い茶色の狐は怠け者の犬を飛び越える
      </AppText>
      <div>600:</div> <AppText variant="div" class="font-semibold">
        素早い茶色の狐は怠け者の犬を飛び越える
      </AppText>
      <div>700:</div> <AppText variant="div" style="font-weight: bold;">
        素早い茶色の狐は怠け者の犬を飛び越える
      </AppText>
    </div>
  </div>

  <div class="test">
    <AppText>Test</AppText>
    <FButton :is-full-width="false" @click="checkBack">
      Check test BE
    </FButton>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const year1 = ref(2023);
const year2 = ref(2023);
const yearsList = [
  { text: '2021', value: 2021 },
  { text: '2022', value: 2022 },
  { text: '2023', value: 2023 },
];
</script>

<style scoped lang="scss">
.test {
  max-width: $contentMaxWidth;
  margin: 0 auto;
  background-color: var(--color-white);
  padding: 40px;
  // filter: drop-shadow(0 2px 5px var(--color-black-015));
  border-bottom: 1px solid var(--color-black-01);
  border-radius: 4px;

  display: grid;
  grid-template-columns: 128px 1fr;
  gap: 32px 0;

  .test2 {
    display: grid;
    grid-template-columns: 32px 1fr;
    gap: 8px 0;
    align-items: center;
  }
}

.orange-variant {
  :deep(.el-input) {
    box-shadow: 0px 0px 0px 2px var(--color-primary-0);
    border-radius: 4px;
    @include transition-base(box-shadow);

    &:not(.is-focus) {
      .el-input__wrapper.is-focus {
        border-color: var(--color-black-01);
        outline: 2px solid var(--color-black);
      }
    }
  }
  :deep(.el-input.is-focus) {
    box-shadow: 0px 0px 0px 2px var(--color-primary-02);
  }
}

.grey-variant {
  :deep(.el-input) {
    box-shadow: 0px 0px 0px 2px var(--color-black-0);
    border-radius: 4px;
    @include transition-base(box-shadow);

    &:not(.is-focus) {
      .el-input__wrapper.is-focus {
        border-color: var(--color-black-01);
        outline: 2px solidvar(--color-black);
      }
    }
  }
  :deep(.el-input.is-focus) {
    box-shadow: 0px 0px 0px 2px var(--color-black-02);

    .el-input__wrapper {
      border-color: #b3b3b3;
    }
  }
}
</style>
